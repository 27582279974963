/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'

import abdul from '../assets/svg/testimonials/Abdul_kalam.jpg'
import mark from '../assets/svg/testimonials/meta_logo.jpg'
import bill from '../assets/svg/testimonials/Bill_gates.jpg'
import elon from '../assets/svg/testimonials/elon_logo.jpg'


export const testimonialsData = [
    {
        id: 1,
        name: 'Dr. A.P.J Abdul Kalam',
        title: 'Scientist & Statesman',
        text: "If you fail, never give up because F.A.I.L. means “First Attempt In Learning”.  End is not the end, in fact E.N.D. means “Effort Never Dies.”  If you get No as an answer, remember N.O. means “Next Opportunity",
        image: abdul
    },
    {
        id: 2,
        name: 'Mark Zuckerberg',
        title: 'CEO of Facebook',
        text: "Move fast and break things. Unless you are breaking stuff, you are not moving fast enough. By giving people the power to share, we're making the world more transparent. A squirrel dying in front of your house may be more relevant to your interests right now than people dying in Africa.",
        image: mark
    },
    {
        id: 3,
        name: 'Bill Gates',
        title: 'American business magnate',
        text: "Success is a lousy teacher. It seduces smart people into thinking they can't lose. If you can't make it good, at least make it look good.",
        image: bill
    },
    {
        id: 4,
        name: 'Elon Musk',
        title: 'Founder of SpaceX',
        text: "I think it's very important to have a feedback loop, where you're constantly thinking about what you've done and how you could be doing it better. I think that's the single best piece of advice: constantly think about how you could be doing things better and questioning yourself.",
        image: elon
    },
    {
        id: 5,
        name: 'Joey Tribbiani',
        title: 'Actor',
        text: "Making my portfolio with this helped me get my role as 'Dr. Drake Ramoray' in 'Days Of Our Lives'. You guys are the best !",
        image: boy1
    },
    {
        id: 6,
        name: 'Rachel Green',
        title: 'Sales Executive',
        text: 'Ralph Lauren was one of the best things that ever happened to me. My portfolio made that dream happen. I owe you guys.',
        image: girl3
    },
    {
        id: 7,
        name: 'Ross Geller',
        title: 'Paleontologist',
        text: "Be it 'Rocks' or 'Bones', you need a website to display it. This is it. Great work guys !",
        image: boy3
    }
]

