import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
// import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'EMM & MDM Kiosk',
        projectDesc: 'This project aims to build streamlined device management and enhanced security protocols for efficient enterprise mobility and effective mobile device control.',
        // projectDesc: 'This project aims to build an employee management system that consolidates all information of a company',
        tags: ['Python', 'Flask', 'Rest API', 'GCP', 'PostgeSQL' ],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: one
    },
    {
        id: 2,
        projectName: 'Trueread Link',
        projectDesc: 'This project involved the development of an OCR-based electric meter detection system, encompassing data collection and management capabilities.',
        tags: ['Python','Django Rest', 'PostgreSQL', 'Rest API',],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: two
    },
    {
        id: 3,
        projectName: 'Payzark Connect',
        projectDesc: 'This project entailed the creation of a versatile platform offering services such as bill payments, money transfers, recharges, and ticket booking.',
        // projectDesc: 'I contributed to a project that centered around creating a versatile platform for various services like bill payments, money transfers, recharges, and ticket bookings.',
        tags: ['Python', 'Django', 'PostgreSQL', 'Rest API'],
        // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: three
    },
    // {
    //     id: 4,
    //     projectName: 'Android Patient Tracker',
    //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
    //     tags: ['Flutter', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: four
    // },
    // {
    //     id: 5,
    //     projectName: 'E-Commerce App',
    //     projectDesc: 'A Simple E-commerce application',
    //     tags: ['React Native', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: five
    // },
    // {
    //     id: 6,
    //     projectName: 'Uber Lite',
    //     projectDesc: 'Uber clone',
    //     tags: ['Flutter'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: six
    // },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/