export const educationData = [
    {
        id: 1,
        institution: 'G.D. Mother International School',
        course: 'Secondary Education',
        startYear: '2012',
        endYear: '2013',
        marks:'74 %'
    },
    {
        id: 2,
        institution: 'L.S. College Muzaffarpur',
        course: 'Higher Secondary Education',
        startYear: '2013',
        endYear: '2015',
        marks : '72.2 %'
    },
    {
        id: 3,
        institution: 'Maulana Abdul Kalam Azad University of Technology',
        course: 'Bachelor of Technology',
        startYear: '2016',
        endYear: '2020',
        marks: '76 %'
    },
]
