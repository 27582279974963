export const achievementData = {
    bio : "Shipped feature (or product) on time/early. \n Improved code quality/performance. \n Increased user engagement/retention.   \n Automated tasks/processes. \n Implemented new technology/framework",
    for_company: "Collaborated with cross-functional teams to define and implement agile development methodologies, increasing team efficiency by 25%.",
    for_company2: "Reduced software bugs by 80% through the implementation of code reviews and strict adherence to coding standards." ,
    for_company3 : " Integrated a continuous integration and delivery pipeline, reducing deployment time by 50% and enabling faster product iterations.",
    for_company4 :  "Successfully resolved a critical security vulnerability, protecting the company’s data and systems from potential breaches.",
    for_company5 :"Increased application efficiency by 20% through the optimization of SQL queries and database indexing.",

    achievements :[
        {
            id : 1,
            title : 'Google Cloud certification',
            details : 'Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.',
            date : 'Jun 21, 2023',
            // field : 'Automation',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'
        },
        {
            id : 2,
            title : 'AWS Certification',
            details : 'Validate technical skills and cloud expertise to grow your career and business.',
            date : 'Sep 11, 2022',
            // field : 'Automation',
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 3,
            title : 'Professional Data Engineer Certification',
            details : 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
            date : 'Jan 20, 2024',
            // field : 'Automation',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
