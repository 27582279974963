// import resumenew from '../assets/pdf/resumenew.pdf'
import myimage from '../assets/png/p2.png'
import image2 from '../assets/png/rajiv_img_1.jpg'

export const headerData = {
    name: 'Rajiv Ranjan',
    title: "Software Engineer",
    desciption:"There wasn't a bird in the sky, but that was not what caught her attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. She knew what was coming and she hoped she was prepared. ",
    // image: 'https://raw.githubusercontent.com/PhantomScript/asset-container/main/developer-portfolio/landingImg.png',
    image : myimage,
    image2 : image2,
    resumePdf: "resumenew",
    // resumePdf: resumenew
    
}
